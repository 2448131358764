import http from "./axios";

export const instanceList = (data) => {
  return http.get("/db/instance/list", data);
};

export const backupList = (data) => {
  return http.get("/db/databaseInfo/backupList", data);
};

export const backupSubList = (data) => {
  return http.get("/db/backupSubList", data);
};

export const searchTag = (data) => {
  return http.get("/db/tag/searchTag", data);
};

export const addTagRelation = (data) => {
  return http.json_post("/db/tag/addTagRelation", data);
};

export const deleteTagRelation = (data) => {
  return http.json_post("/db/tag/deleteTagRelation", data);
};

export const showDatabases = (data) => {
  return http.get("/db/instance/showDatabases", data);
};

export const accountSummary = (data) => {
  return http.get("/db/accessLog/accountSummary", data);
};

export const dbDelete = (data) => {
  return http.json_post("/db/delete", data);
};

export const enableOrDisableBackup = (data) => {
  return http.json_post("/db/databaseInfo/enableOrDisableBackup", data);
};

export const dbCheck = (data) => {
  return http.json_post("/db/check", data);
};

export const dbAddDatabase = (data) => {
  return http.json_post("/db/addDatabase", data);
};

export const hostAddDatabase = (data) => {
  return http.json_post("/db/instance/addDatabaseAccount", data);
};

export const dbList = (data) => {
  return http.get("/db/list", data);
};

export const listRoles = (data) => {
  return http.get("/db/listRoles", data);
};

export const listUserRoles = (data) => {
  return http.get("/db/listUserRoles", data);
};

export const revokeRoleFromUser = (data) => {
  return http.json_post("/db/revokeRoleFromUser", data);
};

export const grantRoleToUser = (data) => {
  return http.json_post("/db/grantRoleToUser", data);
};

export const createRole = (data) => {
  return http.json_post("/db/createRole", data);
};

export const dropRole = (data) => {
  return http.json_post("/db/dropRole", data);
};

export const getDbPassword = (data) => {
  return http.get("/db/getDbPassword", data);
};

export const resetPassword = (data) => {
  return http.json_post("/db/resetPassword", data);
};

export const updateBackupSchedule = (data) => {
  return http.json_post("/db/databaseInfo/updateBackupSchedule", data);
};

export const autoCompleteRole = (data) => {
  return http.json_post("/db/autoCompleteRole", data);
};

// 查询表格中数据库列表
export const databaseInfoList = (data) => {
  return http.get("/db/databaseInfo/list", data);
};

// 冗余索引检测
export const duplicateKeyChecker = (data) => {
  return http.get("/db/pt/duplicateKeyChecker", data);
};

// 冗余索引检测--修复
export const autoRemoveDuplicateKey = (data) => {
  return http.json_post("/db/pt/autoRemoveDuplicateKey", data);
};

// 同步信息
export const instanceSyncInfo = (data) => {
  return http.json_post("/db/instance/syncInfo", data);
};

// 添加数据库名称
export const addDatabaseForInstance = (data) => {
  return http.json_post("/db/instance/addDatabaseForInstance", data);
};

// 开启监控性能
export const openPerformanceMonitor = (data) => {
  return http.json_post("/db/instance/openPerformanceMonitor", data);
};

// 关闭监控性能
export const closePerformanceMonitor = (data) => {
  return http.json_post("/db/instance/closePerformanceMonitor", data);
};

// 性能统计
export const performance = (data) => {
  return http.get("/db/instance/performance", data);
};

// 新增实例
export const addDatabaseHost = (data) => {
  return http.json_post("/db/instance/addDatabase", data);
};

// slave监控
export const updateSlaveDelayMonitorInfo = (data) => {
  return http.json_post("/db/instance/updateSlaveDelayMonitorInfo", data);
};

// 删除实例
export const deleteDase = (data) => {
  return http.post("/db/instance/delete", data);
};

// 锁定账户
export const lockAccount = (data) => {
  return http.json_post("/db/lockAccount", data);
};

// 解锁账户
export const unlockAccount = (data) => {
  return http.json_post("/db/unlockAccount", data);
};

// 修改host
export const dbChangeHost = (data) => {
  return http.json_post("/db/changeHost", data);
};

// 查询备份日志
export const backupListHost = (data) => {
  return http.get("/db/databaseInfo/backupList", data);
};

// 查询子备份
export const backupSubListHost = (data) => {
  return http.get("/db/instance/backupSubList", data);
};

// 查询用户列表
export const sqlUerList = (data) => {
  return http.get("/db/sql/userListByPrefix", data);
};

// 查询实例中的数据库和表
export const dbInfoList = (data) => {
  return http.get("/db/sql/dbInfoList", data);
};

// 实例中查询指定用户当前拥有权限的数据库和表
export const showPrivileges = (data) => {
  return http.get("/db/sql/showPrivileges", data);
};

// 给指定用户授予授权
export const sqlGrant = (data) => {
  return http.json_post("/db/sql/grant", data);
};

// 保存策略备份
export const updateBackupScheduleHost = (data) => {
  return http.json_post("/db/instance/updateBackupSchedule", data);
};

// 更新
export const updateBasicInfo = (data) => {
  return http.json_post("/db/instance/updateBasicInfo", data);
};

// 请求table/tableList
export const tableList = (data) => {
  return http.get("/db/table/tableList", data);
};
