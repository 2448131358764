<template>
  <div class="sqlAuditList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        状态：
        <a-select
          style="width: 240px"
          v-model="sqlAuditStatus"
          placeholder="请选择状态"
          allowClear
        >
          <a-select-option v-for="item in statusList" :key="item.value">{{
            item.label
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        时间：
        <a-date-picker
          :disabledDate="disabledLastLoginStartDate"
          format="YYYY-MM-DD"
          v-model="lastLoginDateStart"
          placeholder="开始日期"
          @openChange="handleLastLoginStartOpenChange"
        />
        <i style="display: inline-block; margin-left: 20px"></i>
        <a-date-picker
          :disabledDate="disabledLastLoginEndDate"
          format="YYYY-MM-DD"
          placeholder="结束日期"
          v-model="lastLoginDateEnd"
          :open="loginEndOpen"
          @openChange="handleLastLoginEndOpenChange"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addSqlAuditClick" icon="plus"
          >新增</a-button
        >
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(sqlAuditColumns)"
      :columns="sqlAuditColumns"
      :dataSource="sqlAuditDataSource"
      :pagination="sqlAuditPagination"
      size="small"
    >
      <span slot="statusLabel" slot-scope="text, record" style="width: 100%">
        <a-tag :color="record.color"
          >{{ text }}<a-icon v-if="text === '执行中'" type="loading"
        /></a-tag>
        <a-tooltip>
          <template slot="title">存在更新条数为0的sql</template>
          <a-icon
            type="exclamation-circle"
            theme="twoTone"
            two-tone-color="#FFD700"
            v-if="
              record.isAffectCountZero === true && record.status === 'SUCCESS'
            "
          />
        </a-tooltip>
      </span>
      <span
        slot="content"
        slot-scope="text, record"
        style="
          display: inline-block;
          max-width: 230px;
          overflow: hidden;
          text-overflow: ellipsis;
        "
      >
        <span :title="text">{{ text }}</span>
      </span>
      <span slot="databaseName" slot-scope="text, record" style="width: 100%">
        <a-tooltip>
          <template slot="title">{{ record.instanceAddress }}</template>
          <span>{{ text }}</span>
        </a-tooltip>
      </span>
      <span slot="executeTime" slot-scope="text, record" style="width: 100%">
        <span v-if="record.executeTime.indexOf('2000') > -1"></span>
        <span v-else>{{ record.executeTime }}</span>
      </span>
      <span slot="scheduled" slot-scope="text" style="width: 100%">
        <a-tag color="green" v-if="text"> 定时执行 </a-tag>
        <a-tag color="blue" v-else> 立即执行 </a-tag>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="checkDetails(record)">查看SQL</a>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它操作 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="executeNow(record)"
                :disabled="
                  !(
                    (record.status == 'WAIT_EXECUTE' ||
                      record.status == 'AUDIT_SUCCESS') &&
                    record.scheduled
                  )
                "
              >
                <template slot="title"
                  >确认执行 {{ record.databaseName }} 的sql吗</template
                >
                <a-button
                  type="link"
                  :disabled="
                    !(
                      (record.status == 'WAIT_EXECUTE' ||
                        record.status == 'AUDIT_SUCCESS') &&
                      record.scheduled
                    )
                  "
                  >立即执行</a-button
                >
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item>
              <a-button type="link" @click="again(record)">重新创建</a-button>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="right"
                okText="是"
                cancelText="否"
                @confirm="cancelSqlPlanOk(record)"
                :disabled="record.cancelExecutionDis"
              >
                <template slot="title">是否取消执行</template>
                <a-button type="link" :disabled="record.cancelExecutionDis"
                  >取消执行</a-button
                >
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <!-- 新增  -->
    <a-modal
      title="新增"
      v-model="visibleAdd"
      :maskClosable="false"
      :afterClose="closeAdd"
      width="800px"
    >
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-item label="选择实例:">
          <a-select
            showSearch
            v-model="actionForm.instanceId"
            placeholder="可搜索IP地址"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="handleInstanceListSearch"
            @change="handleInstanceListChange"
            :notFoundContent="fetching ? undefined : null"
            allowClear
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in instanceList" :key="d.id"
              >{{ d.name }}({{ d.host }}:{{ d.port }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="数据库名:" class="dbNameClass">
          <a-select
            v-model="actionForm.databaseName"
            placeholder="请选择数据库名称"
            :disabled="!actionForm.instanceId"
            showSearch
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="databaseNameSearch"
            allowClear
          >
            <a-select-opt-group label="customer">
              <a-select-option v-for="d in customerList" :key="d.value">{{
                d.label
              }}</a-select-option>
            </a-select-opt-group>
            <a-select-opt-group label="system">
              <a-select-option v-for="d in systemList" :key="d.value">{{
                d.label
              }}</a-select-option>
            </a-select-opt-group>
          </a-select>
        </a-form-item>
        <a-form-item>
          <span slot="label">
            执行sql&nbsp;
            <a-tooltip title="支持一条sql占用多行，多个sql用';'(分号) 分割">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-textarea
            v-model="actionForm.content"
            placeholder="请输入执行sql"
            :auto-size="{ minRows: 6, maxRows: 6 }"
          ></a-textarea>
          <a href="javascript:;" @click="loopGenerationSql">生成SQL</a>
        </a-form-item>
        <a-form-item label="执行时间:">
          <div>
            <a-checkbox :checked="isImplement" @change="onChangeTiming">
              定时执行
            </a-checkbox>
            <a-date-picker
              v-model="actionForm.executeTime"
              show-time
              placeholder="请选择执行时间"
              format="YYYY-MM-DD HH:mm:ss"
              v-if="isImplement"
            />
          </div>
        </a-form-item>
        <a-form-item label="执行方式:">
          <a-radio-group v-model="executeWay">
            <a-radio value="JDBC"> JDBC </a-radio>
            <a-radio value="PT"> PT </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="顺序执行:">
          <a-switch
            checkedChildren="开启"
            unCheckedChildren="关闭"
            v-model="executeInOrder"
          />
        </a-form-item>
        <a-form-item label="备注:">
          <a-textarea
            v-model="actionForm.remark"
            placeholder="请输入备注"
            :auto-size="{ minRows: 6, maxRows: 6 }"
          ></a-textarea>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleAdd = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addOk"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 生成SQL -->
    <a-modal
      title="生成SQL"
      v-model="generationSqlShow"
      :maskClosable="false"
      width="600px"
      class="generationSqlModal"
    >
      <a-tabs v-model="generationSqlActive">
        <a-tab-pane key="1" tab="循环生成SQL"> </a-tab-pane>
        <a-tab-pane key="2" tab="范围生成SQL"> </a-tab-pane>
      </a-tabs>
      <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
        <a-form-item label="SQL:">
          <a-textarea
            id="sqlInput"
            v-if="generationSqlActive == '1'"
            v-model="generateSqlForm.originalLoopSql"
            placeholder="请输入SQL"
            :auto-size="{ minRows: 3, maxRows: 6 }"
          />
          <a-textarea
            id="sqlInput"
            v-else
            v-model="generateSqlForm.originalScopeSql"
            placeholder="请输入SQL"
            :auto-size="{ minRows: 3, maxRows: 6 }"
          />
        </a-form-item>
        <a-form-item label="标签:">
          <a
            href="javascript:void(0)"
            @click="loopChain('${loop}')"
            class="template"
            v-if="generationSqlActive == '1'"
            >${loop}</a
          >
          <template v-else>
            <a
              href="javascript:void(0)"
              @click="loopChain('${minId}')"
              class="template"
              >${minId}</a
            >
            <a
              href="javascript:void(0)"
              @click="loopChain('${maxId}')"
              class="template"
              style="margin-left: 5px"
              >${maxId}</a
            >
          </template>
        </a-form-item>
        <a-form-item required>
          <span slot="label">
            {{ generationSqlActive == "1" ? "循环" : "范围" }}
            <a-tooltip v-if="generationSqlActive == '2'" title="闭区间">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          从<a-input
            v-model="generateSqlForm.start"
            style="margin: 0 5px; width: 150px"
          />到<a-input
            v-model="generateSqlForm.end"
            style="margin: 0 5px; width: 150px"
          />
        </a-form-item>
        <a-form-item required label="间隔:" v-if="generationSqlActive == '2'">
          <a-input v-model="generateSqlForm.step" style="width: 100px" />
        </a-form-item>
      </a-form>
      <a-divider />
      <a-button
        type="primary"
        @click="loopGenerationOk"
        style="margin-bottom: 10px"
        >生成</a-button
      >
      <template v-if="generationSqlActive == '1'">
        <a-textarea
          v-model="generateSqlForm.loopSql"
          placeholder="生成循环SQL"
          :auto-size="{ minRows: 15, maxRows: 15 }"
        />
        <div v-if="generateSqlForm.loopSqlCount > 0">
          共{{ generateSqlForm.loopSqlCount }}条SQL
        </div>
      </template>
      <template v-else>
        <a-textarea
          v-model="generateSqlForm.scopeSql"
          placeholder="生成范围SQL"
          :auto-size="{ minRows: 15, maxRows: 15 }"
        />
        <div v-if="generateSqlForm.scopeSqlCount > 0">
          共{{ generateSqlForm.scopeSqlCount }}条SQL
        </div>
      </template>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button
            type="primary"
            v-if="generationSqlActive == '1'"
            @click="generationSqlClose"
            >使用并关闭</a-button
          >
          <a-button
            type="primary"
            v-if="generationSqlActive == '2'"
            @click="generationSqlClose"
            >使用并关闭</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 查看SQL -->
    <a-modal
      title="查看SQL"
      v-model="checkSqlShow"
      :maskClosable="false"
      width="1000px"
      :zIndex="3000"
    >
      <a-textarea
        v-model="checkSqlContent"
        placeholder="SQL"
        :auto-size="{ minRows: 25, maxRows: 25 }"
      ></a-textarea>
      <div v-if="remark !== ''" style="margin-top: 10px">
        备注：{{ remark }}
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-popconfirm
            title="确定要通过吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="auditConfirmStatus(true)"
          >
            <a-button
              type="primary"
              v-if="checkSqlAuditConfirmBtnDisabled === false"
              >审核通过</a-button
            >
          </a-popconfirm>
          <a-popconfirm
            title="确定要驳回吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="auditConfirmStatus(false)"
          >
            <a-button
              type="danger"
              v-if="checkSqlAuditConfirmBtnDisabled === false"
              >审核驳回</a-button
            >
          </a-popconfirm>
          <a-button @click="checkSqlShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 查看详情 -->
    <a-modal
      title="查看SQL"
      v-model="checkDetailsShow"
      :maskClosable="false"
      :afterClose="checkDetailsClose"
      width="1200px"
      class="checkDetail"
    >
      <a-tabs v-model="checkDetailsActive">
        <a-tab-pane key="1" tab="详情">
          <div class="progress">
            <span class="label">进度: </span>
            <a-progress :percent="progress" />
            <span class="num">{{ success }} / {{ sum }} </span>
          </div>
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(checkDetailsColumns)"
            :columns="checkDetailsColumns"
            :dataSource="checkDetailsDataSource"
            :pagination="checkDetailsPagination"
            size="small"
          >
            <span slot="cost" slot-scope="text, record">
              {{ timeConsuming(record.cost) }}
            </span>
            <span slot="statusLabel" slot-scope="text, record">
              <a-tooltip>
                <template v-if="text === '执行失败'" slot="title">
                  {{ record.errorMessage }}</template
                >
                <a-tag :color="record.color"
                  >{{ text }}<a-icon v-if="text === '执行中'" type="loading" />
                  <a-icon
                    style="margin-left: 5px"
                    v-if="text === '执行失败'"
                    type="question-circle"
                  />
                </a-tag>
              </a-tooltip>
            </span>
            <span slot="content" slot-scope="text, record">
              <span :title="text">{{ text }}</span>
            </span>
            <span slot="action" slot-scope="text, record">
              <a href="javascript:;" @click="checkSql(record)">查看SQL</a>
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="SQL">
          <a-textarea
            v-model="checkSqlContent"
            placeholder="SQL"
            :auto-size="{ minRows: 25, maxRows: 25 }"
          ></a-textarea>
          <div v-if="remark !== ''" style="margin-top: 10px">
            备注：{{ remark }}
          </div>
        </a-tab-pane>
      </a-tabs>

      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-popconfirm
            title="确定要通过吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="auditConfirmStatus(true)"
          >
            <a-button type="primary" v-if="auditConfirmBtnDisabled === false"
              >审核通过</a-button
            >
          </a-popconfirm>
          <a-popconfirm
            title="确定要驳回吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="auditConfirmStatus(false)"
          >
            <a-button type="danger" v-if="auditConfirmBtnDisabled === false"
              >审核驳回</a-button
            >
          </a-popconfirm>
          <a-button @click="checkDetailsShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/sqlAuditList.js";
import { showDatabases, instanceList } from "../lib/databaseList.js";
import { userSqlLogList, userSqlLogRefresh } from "../lib/userSqlLogList.js";
import moment from "moment";

export default {
  name: "sqlAuditList",
  data() {
    return {
      executeWay: "JDBC",
      executeInOrder: true,
      remark: "",
      lastLoginDateStart: null,
      lastLoginDateEnd: null,
      loginEndOpen: false,
      relevantId: "",
      checkDetailsColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 80,
        },
        {
          title: "数据库名称",
          ellipsis: true,
          dataIndex: "databaseName",
          width: 120,
        },
        {
          title: "表名称",
          ellipsis: true,
          dataIndex: "tableName",
          width: 250,
        },
        {
          title: "执行sql",
          ellipsis: true,
          dataIndex: "content",
          width: 300,
          scopedSlots: { customRender: "content" },
        },
        {
          title: "影响行数",
          ellipsis: true,
          dataIndex: "affectCount",
          width: 100,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "statusLabel",
          scopedSlots: { customRender: "statusLabel" },
          align: "center",
          width: 100,
        },
        {
          title: "耗时(ms)",
          ellipsis: true,
          dataIndex: "cost",
          scopedSlots: { customRender: "cost" },
          width: 100,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      checkDetailsDataSource: [],
      checkDetailsPagination: {},
      checkDetailsShow: false,
      checkDetailsActive: "1",
      checkSqlShow: false,
      sqlId: "",
      auditConfirmBtnDisabled: true,
      checkSqlAuditConfirmBtnDisabled: true,
      checkSqlContent: "",
      generationSqlShow: false,
      generationSqlActive: "1",
      generateSqlForm: {
        originalLoopSql: "",
        originalScopeSql: "",
        loopSql: "",
        scopeSql: "",
        start: undefined,
        end: undefined,
        step: "",
        loopSqlCount: 0,
        scopeSqlCount: 0,
      },
      isImplement: false,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      sqlAuditStatus: undefined,
      statusList: [
        {
          value: "WAIT_AUDIT",
          label: "待审核",
          color: "orange",
        },
        {
          value: "AUDIT_SUCCESS",
          label: "审核成功",
          color: "orange",
        },
        {
          value: "CANCELED",
          label: "已取消",
          color: "",
        },
        {
          value: "AUDIT_FAILURE",
          label: "审核失败",
          color: "red",
        },
        {
          value: "SUCCESS",
          label: "执行成功",
          color: "green",
        },
        {
          value: "EXCEPTION",
          label: "执行异常",
          color: "red",
        },
        {
          value: "FAILURE",
          label: "执行失败",
          color: "red",
        },
        {
          value: "PART_FAILURE",
          label: "部分失败",
          color: "red",
        },
        {
          value: "EXECUTING",
          label: "执行中",
          color: "orange",
        },
        {
          value: "WAIT_EXECUTE",
          label: "等待执行",
          color: "orange",
        },
      ],
      sqlAuditColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "数据库名称",
          ellipsis: true,
          dataIndex: "databaseName",
          scopedSlots: { customRender: "databaseName" },
          width: 200,
        },
        {
          title: "执行sql",
          ellipsis: true,
          dataIndex: "content",
          width: 250,
          scopedSlots: { customRender: "content" },
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "statusLabel",
          scopedSlots: { customRender: "statusLabel" },
          width: 120,
        },
        {
          title: "提交人",
          ellipsis: true,
          dataIndex: "operatorName",
          width: 120,
        },
        {
          title: "审核人",
          ellipsis: true,
          dataIndex: "auditorName",
          width: 120,
        },
        {
          title: "执行方式",
          ellipsis: true,
          dataIndex: "executeWay",
          width: 120,
        },
        {
          title: "执行时间",
          ellipsis: true,
          dataIndex: "executeTime",
          scopedSlots: { customRender: "executeTime" },
          width: 170,
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "scheduled",
          scopedSlots: { customRender: "scheduled" },
          width: 120,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 150,
        },
      ],
      sqlAuditDataSource: [],
      sqlAuditPagination: {},
      pageNo: 1,
      visibleAdd: false,
      loading: false,
      actionForm: {
        instanceId: undefined,
        instanceHost: undefined,
        databaseName: undefined,
        content: "",
        executeTime: null,
        remark: "",
      },
      customerList: [],
      systemList: [],
      fetching: false,
      instanceList: [],
      detailTimer: null,
      queryTimer: null,
      sum: 0,
      success: 0,
      progress: 0,
    };
  },
  computed: {
    lastLoginDateEndTime() {
      // 开始时间
      if (this.lastLoginDateEnd) {
        return this.$common.transformTime(this.lastLoginDateEnd);
      }
      return null;
    },
    lastLoginDateStartTime() {
      // 结束时间
      if (this.lastLoginDateStart) {
        return this.$common.transformTime(this.lastLoginDateStart);
      }
      return null;
    },
    timeConsuming() {
      return (cost) => {
        let seconds = Math.floor(cost / 1000);
        let hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        let minutes = Math.floor(seconds / 60);
        seconds %= 60;

        // 补零函数，使得数字始终为两位数
        const pad = (num) => (num < 10 ? "0" + num : num);

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
      };
    },
  },
  mounted() {
    let nowdate = new Date();
    let startTime = new Date(nowdate - 7 * 24 * 3600 * 1000);
    startTime.setHours(0);
    startTime.setMinutes(0);
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);
    let endTime = new Date();
    endTime.setHours(23);
    endTime.setMinutes(59);
    endTime.setSeconds(59);
    endTime.setMilliseconds(59);
    this.lastLoginDateStart = moment(startTime, "YYYY-MM-DD HH:mm:ss");
    this.lastLoginDateEnd = moment(endTime, "YYYY-MM-DD HH:mm:ss");
    this.query(1);
  },
  methods: {
    // 不可选的登录日期
    disabledLastLoginStartDate(startValue) {
      const endValue = this.lastLoginDateEnd;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledLastLoginEndDate(endValue) {
      const startValue = this.lastLoginDateStart;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    // 选择登录日期
    handleLastLoginStartOpenChange(open) {
      if (!open) {
        this.loginEndOpen = true;
      }
    },
    handleLastLoginEndOpenChange(open) {
      this.loginEndOpen = open;
    },
    executeNow(val) {
      api.executeNow({ id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("执行成功");
          this.query();
        }
      });
    },
    // 点击查看详情
    checkDetails(val) {
      this.checkDetailsActive = "1";
      this.checkSqlContent = val.content;
      this.sqlId = val.id;
      if (val.status === "WAIT_AUDIT") {
        this.auditConfirmBtnDisabled = false;
      } else {
        this.auditConfirmBtnDisabled = true;
      }
      this.remark = val.remark;
      this.relevantId = val.id;
      this.getUserSqlLogProgress();
      this.queryCheckDetails(1);
    },
    // 关闭查看详情
    checkDetailsClose() {
      this.checkDetailsDataSource = [];
      if (this.detailTimer) {
        clearTimeout(this.detailTimer);
        this.detailTimer = null;
      }
    },
    getUserSqlLogProgress() {
      let data = {
        relevantId: this.relevantId,
      };
      api.userSqlLogProgress(data).then((res) => {
        if (res.result === 200) {
          this.sum = res.data.sum;
          this.success = res.data.success;
          this.progress = ((this.success / this.sum) * 100).toFixed(2) * 1;
        }
      });
    },
    // 点击查询
    queryCheckDetails(index) {
      let data = {
        pageNo: index,
        pageSize: 10,
        relevantId: this.relevantId,
        type: "SQL_AUDIT",
      };
      userSqlLogList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.key = index + 1;
            switch (item.status) {
              case "SUCCESS":
                item.statusLabel = "执行成功";
                item.color = "green";
                break;
              case "EXCEPTION":
                item.statusLabel = "执行异常";
                item.color = "red";
                break;
              case "FAILURE":
                item.statusLabel = "执行失败";
                item.color = "red";
                break;
              case "WAIT_EXECUTE":
                item.statusLabel = "等待执行";
                item.color = "orange";
                break;
              case "EXECUTING":
                item.statusLabel = "执行中";
                item.color = "orange";
                break;
              case "PART_FAILURE":
                item.statusLabel = "部分失败";
                item.color = "red";
                break;
            }
          });
          this.checkDetailsDataSource = list;
          let idList = [];
          this.checkDetailsDataSource.forEach((item) => {
            if (item.status == "EXECUTING" || item.status == "WAIT_EXECUTE") {
              idList.push(item.id);
            }
          });
          if (idList.length > 0) {
            this.userSqlLogRefresh(idList);
          }
          this.checkDetailsPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.checkDetailsChangePageItem(current),
          };
          this.checkDetailsShow = true;
        }
      });
    },
    userSqlLogRefresh(idList) {
      userSqlLogRefresh({ idList }).then((res) => {
        if (res.result == 200) {
          let idList = [];
          res.data.forEach((newItem) => {
            if (
              newItem.status == "EXECUTING" ||
              newItem.status == "WAIT_EXECUTE"
            ) {
              idList.push(newItem.id);
            }
            this.checkDetailsDataSource.forEach((item) => {
              if (newItem.id == item.id) {
                let statusLabel = "",
                  color = "";
                switch (newItem.status) {
                  case "SUCCESS":
                    statusLabel = "执行成功";
                    color = "green";
                    break;
                  case "EXCEPTION":
                    statusLabel = "执行异常";
                    color = "red";
                    break;
                  case "FAILURE":
                    statusLabel = "执行失败";
                    color = "red";
                    break;
                  case "WAIT_EXECUTE":
                    statusLabel = "等待执行";
                    color = "orange";
                    break;
                  case "EXECUTING":
                    statusLabel = "执行中";
                    color = "orange";
                    break;
                  case "PART_FAILURE":
                    statusLabel = "部分失败";
                    color = "red";
                    break;
                }
                this.$set(item, "status", newItem.status);
                this.$set(item, "cost", newItem.cost);
                this.$set(item, "statusLabel", statusLabel);
                this.$set(item, "color", color);
              }
            });
          });
          if (this.detailTimer) {
            clearInterval(this.detailTimer);
            this.detailTimer = null;
          }
          if (idList.length > 0) {
            this.detailTimer = setInterval(() => {
              this.userSqlLogRefresh(idList);
              this.getUserSqlLogProgress();
            }, 2000);
          } else if (this.progress < 100) {
            this.detailTimer = setInterval(() => {
              this.getUserSqlLogProgress();
            }, 2000);
          }
        }
      });
    },
    // 翻页
    checkDetailsChangePageItem(index) {
      this.queryCheckDetails(index);
    },
    // 点击查看sql
    checkSql(val) {
      this.checkSqlShow = true;
      this.checkSqlContent = val.content;
      this.sqlId = val.id;
      if (val.status === "WAIT_AUDIT") {
        this.checkSqlAuditConfirmBtnDisabled = false;
      } else {
        this.checkSqlAuditConfirmBtnDisabled = true;
      }
      this.remark = val.remark;
    },
    // 点击循环生成SQL
    loopGenerationSql() {
      this.generationSqlActive = "1";
      this.generateSqlForm = {
        originalLoopSql: "",
        originalScopeSql: "",
        loopSql: "",
        scopeSql: "",
        start: undefined,
        end: undefined,
        step: 500000,
      };
      let generateSqlForm = localStorage.getItem(
        this.actionForm.instanceId +
          "_" +
          this.actionForm.databaseName +
          "_generateSqlForm"
      );
      if (generateSqlForm) {
        this.generateSqlForm = JSON.parse(generateSqlForm);
      }
      this.generationSqlShow = true;
    },
    // 点击插入标签
    loopChain(title) {
      if (this.generationSqlActive == "1") {
        this.generateSqlForm.originalLoopSql = this.$common.insertInputTxt(
          title,
          "sqlInput",
          this.generateSqlForm.originalLoopSql
        );
      } else {
        this.generateSqlForm.originalScopeSql = this.$common.insertInputTxt(
          title,
          "sqlInput",
          this.generateSqlForm.originalScopeSql
        );
      }
    },
    // 生成sql
    loopGenerationOk() {
      if (this.generationSqlActive == "1") {
        let data = {
          sql: this.generateSqlForm.originalLoopSql,
          num: this.generateSqlForm.start + "-" + this.generateSqlForm.end,
        };
        api.loopGenerateSql(data).then((res) => {
          if (res.result == 200) {
            this.generateSqlForm.loopSql = res.data.join("\n");
            this.generateSqlForm.loopSqlCount = res.data.length;
          }
        });
      } else {
        let data = {
          sql: this.generateSqlForm.originalScopeSql,
          start: this.generateSqlForm.start,
          end: this.generateSqlForm.end,
          step: this.generateSqlForm.step,
        };
        for (const key in data) {
          const value = data[key];
          if (!value) {
            this.$message.warning("请填写必填信息");
            return;
          }
        }

        api.idRangeGenerateSql(data).then((res) => {
          if (res.result == 200) {
            this.generateSqlForm.scopeSql = res.data.join("\n");
            this.generateSqlForm.scopeSqlCount = res.data.length;
          }
        });
      }
    },
    generationSqlClose() {
      if (this.actionForm.instanceId && this.actionForm.databaseName) {
        let sign =
          this.actionForm.instanceId + "_" + this.actionForm.databaseName;
        localStorage.setItem(
          sign + "_generateSqlForm",
          JSON.stringify(this.generateSqlForm)
        );
      }
      if (this.generationSqlActive == "1") {
        this.actionForm.content = this.generateSqlForm.loopSql;
      } else {
        this.actionForm.content = this.generateSqlForm.scopeSql;
      }
      this.generationSqlShow = false;
    },
    // 选择定时发送
    onChangeTiming(e) {
      this.isImplement = e.target.checked;
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        status: this.sqlAuditStatus,
        startTime: this.lastLoginDateStartTime,
        endTime: this.lastLoginDateEndTime,
      };
      this.getSqlAuditList(data);
    },
    // 点击重新执行
    again(val) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      this.getInstanceList(data);
      this.visibleAdd = true;
      this.actionForm.instanceId = val.instanceId;
      this.actionForm.databaseName = val.databaseName;
      this.actionForm.content = val.content;
      this.actionForm.remark = val.remark;
      this.executeWay = val.executeWay;
      this.executeInOrder = val.executeInOrder;
    },
    // 点击新增
    addSqlAuditClick() {
      this.visibleAdd = true;

      let instanceId = localStorage.getItem("actionFormInstanceId");
      let instanceHost = localStorage.getItem("actionFormInstanceHost");
      let databaseName = localStorage.getItem("actionFormDatabaseName");
      if (instanceId) {
        this.actionForm.instanceId = instanceId * 1;
      }
      if (databaseName) {
        this.actionForm.databaseName = databaseName;
      }
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      if (instanceHost && instanceHost !== "undefined") {
        data.host = instanceHost;
      }
      this.getInstanceList(data);
    },
    // 确定新增
    addOk() {
      let data = {
        instanceId: this.actionForm.instanceId,
        databaseName: this.actionForm.databaseName,
        content: this.actionForm.content,
        executeTime: this.$common.transformTime(this.actionForm.executeTime),
        remark: this.actionForm.remark,
        executeWay: this.executeWay,
        executeInOrder: this.executeInOrder,
      };
      if (!this.actionForm.executeTime) {
        data.executeTime = null;
      }
      api.addSqlAudit(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("新增成功");
          this.visibleAdd = false;
          this.query(1);
          localStorage.setItem(
            "actionFormInstanceId",
            this.actionForm.instanceId
          );
          localStorage.setItem(
            "actionFormInstanceHost",
            this.actionForm.instanceHost
          );
          localStorage.setItem(
            "actionFormDatabaseName",
            this.actionForm.databaseName
          );
        }
      });
    },
    // 关闭新增
    closeAdd() {
      this.actionForm = {
        instanceId: undefined,
        databaseName: undefined,
        content: "",
        executeTime: null,
        remark: "",
      };
      this.isImplement = false;
      this.executeWay = "JDBC";
      this.executeInOrder = true;
    },
    // 查询sql审核列表
    getSqlAuditList(data) {
      api.sqlAuditList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          let arr = [];
          list.forEach((item, index) => {
            item.key = index + 1;
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            item.executeTime = this.$common.transformTime(item.executeTime);
            item.cancelExecution = false;
            this.statusList.forEach((status) => {
              if (item.status == status.value) {
                item.statusLabel = status.label;
              }
            });
            switch (item.status) {
              case "WAIT_AUDIT":
                item.color = "orange";
                item.cancelExecution = true;
                break;
              case "AUDIT_SUCCESS":
                item.color = "orange";
                item.cancelExecution = true;
                break;
              case "CANCELED":
                break;
              case "AUDIT_FAILURE":
                item.color = "red";
                break;
              case "SUCCESS":
                item.color = "green";
                break;
              case "EXCEPTION":
                item.color = "red";
                break;
              case "FAILURE":
                item.color = "red";
                break;
              case "PART_FAILURE":
                item.color = "red";
                break;
              case "EXECUTING":
                item.color = "orange";
                item.cancelExecution = true;
                break;
              case "WAIT_EXECUTE":
                item.color = "orange";
                break;
            }
            if (!item.scheduled && item.cancelExecution) {
              item.cancelExecutionDis = false;
            } else {
              item.cancelExecutionDis = true;
            }
            arr.push(item.status);
          });
          this.sqlAuditDataSource = list;
          this.sqlAuditPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePageItem(current),
          };
          if (arr.indexOf("EXECUTING") > -1 && !this.queryTimer) {
            clearInterval(this.queryTimer);
            this.queryTimer = null;

            this.queryTimer = setInterval(() => {
              this.query();
            }, 2000);
          } else if (this.queryTimer) {
            clearInterval(this.queryTimer);
            this.queryTimer = null;
          }
        }
      });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 点击审核--通过
    auditConfirm(val) {
      let data = {
        id: val.id,
        passed: true,
      };
      this.auditSql(data, "ok");
    },
    // 点击审核--驳回
    auditConfirmCancel(val) {
      let data = {
        id: val.id,
        passed: false,
      };
      this.auditSql(data, "cancel");
    },
    // 点击审核通过or驳回
    auditConfirmStatus(val) {
      if (val) {
        let data = {
          id: this.sqlId,
          passed: true,
        };
        this.auditSql(data, "ok");
      } else {
        let data = {
          id: this.sqlId,
          passed: false,
        };
        this.auditSql(data, "cancel");
      }
    },
    // 审核操作
    auditSql(data, sign) {
      api.auditSql(data).then((res) => {
        if (res.result === 200) {
          this.query();
          this.checkSqlShow = false;
          this.checkDetailsShow = false;
          if (sign === "ok") {
            this.$message.success("审核已通过");
          } else {
            this.$message.success("审核已驳回");
          }
        }
      });
    },
    // 确定取消执行
    cancelSqlPlanOk(val) {
      api.cancelSqlPlan({ id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("操作成功");
          this.query();
        }
      });
    },
    // 搜索实例
    handleInstanceListSearch(val) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        host: val,
      };
      this.fetching = true;
      this.getInstanceList(data);
    },
    // 查询实例
    getInstanceList(data) {
      instanceList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = [];
          let list = res.data.records;
          this.instanceList = list;
          this.fetching = false;
        }
      });
    },
    // 选择实例
    handleInstanceListChange(instanceId) {
      this.actionForm.databaseName = undefined;
      if (instanceId) {
        this.actionForm.instanceHost = this.instanceList.filter((item) => {
          return item.id == instanceId;
        })[0].host;
        this.getShowDatabases({ instanceId });
      } else {
        this.handleInstanceListSearch();
      }
    },
    databaseNameSearch(databaseName) {
      this.getShowDatabases({
        instanceId: this.actionForm.instanceId,
        databaseName,
      });
    },
    // 查询新增账号数据库
    getShowDatabases(data) {
      showDatabases(data).then((res) => {
        if (res.result === 200) {
          let data = res.data;
          this.customerList = [];
          this.systemList = [];
          for (let i in data) {
            if (i === "customer") {
              data[i].forEach((item) => {
                let param = {
                  label: item,
                  value: item,
                };
                this.customerList.push(param);
              });
            }
            if (i === "system") {
              data[i].forEach((item) => {
                let param = {
                  label: item,
                  value: item,
                };
                this.systemList.push(param);
              });
            }
          }
        }
      });
    },
  },
  beforeDestroy() {
    if (this.queryTimer) {
      clearInterval(this.queryTimer);
      this.queryTimer = null;
    }
  },
};
</script>
<style lang="scss">
.ant-popover {
  z-index: 9999;
}
.generationSqlModal {
  .ant-form-item {
    margin-bottom: 12px !important;
  }
  .ant-divider {
    margin: 21px 0 !important;
  }
}
.checkDetail {
  .ant-modal-body {
    padding-top: 0 !important;
  }

  .progress {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    .ant-progress {
      width: calc(100% - 160px);
    }
    .label {
      margin-right: 5px;
    }
    .num {
      margin-left: auto;
    }
  }
}
</style>
