<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view name="first" />
    </div>
  </a-config-provider>
</template>
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  data() {
    return {
      zh_CN,
    };
  },
};
</script>
<style lang="scss">
html,
body,
div,
ul,
b,
li,
i,
em,
h1,
h2,
h3,
span,
a {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}
#app {
  height: 100vh;
}

.cronTooltip {
  .ant-tooltip-inner {
    width: 550px;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
.he-img-wrap {
  background: rgba($color: #000000, $alpha: 0.5) !important;
}

.searchCondition {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .searchConditionItem {
    display: flex;
    margin-right: 20px;
    margin-bottom: 20px;
    align-items: center;
    span {
      white-space: nowrap;
    }
    .ant-input-affix-wrapper {
      width: 240px;

      .ant-input {
        width: 100%;
      }
    }
    .ant-input {
      width: 240px;
    }

    .ant-select {
      width: 240px;
    }
    .ant-calendar-picker-input {
      width: 100% !important;
    }
  }
}

.searchButton {
  button {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.ant-btn-link {
  padding: 0 !important;
  &[disabled] {
    color: #00000040 !important;
  }
}
a[disabled] {
  cursor: not-allowed !important;
  pointer-events: none !important;
  color: #00000040 !important;
}
.ant-table-body {
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ededed;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #7c7c7c;
  }
}
.ant-table {
  border: none !important;
  font-size: 12px !important;
  font-weight: 500;
  color: #000 !important;
  table {
    border: 1px solid #e8e8e8 !important;
    .ant-table-thead > tr > th {
      font-weight: 500;
      background: #fafafa !important;
      border-bottom: 1px solid #509ee3 !important;
    }
    .ant-table-tbody {
      td {
        border: none !important;
      }
    }
  }
  .ant-table-fixed-right {
    border-left: none !important;
  }
}
.ant-popover-message-title {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.reminder {
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
  color: black;
  font-size: 20px;

  span {
    color: #cdcdcd;
    font-size: 14px;
    margin-left: 10px;
  }
}
.resize-table-th {
  position: relative;
  .table-draggable-handle {
    transform: none !important;
    position: absolute;
    height: 100% !important;
    bottom: 0;
    left: auto !important;
    right: -5px;
    cursor: col-resize;
    touch-action: none;
  }
}
.slotCell {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .content {
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
}
.dark-row {
  background: #e6f7ff42;
}
.light-row {
  background: #ffffff;
}
.rage {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}
</style>
